
import Vue from "vue";
import SignUpForm from "@/components/SignUp/SignUpForm.vue";
import SignUpInvitation from "@/components/SignUp/SignUpInvitation.vue";

export default Vue.extend({
  name: "SignUp",
  components: {
    SignUpForm,
    SignUpInvitation
  },
  props: {
    userType: String,
    invited: Boolean
  },
  data() {
    return {
      isPlay: false,
      videoId: "tnpXX1Xp1vM"
    };
  },
  computed: {
    player() {
      //@ts-ignore
      return this.$refs.youtube.player;
    }
  },
  methods: {
    playVideo() {
      //@ts-ignore
      this.isPlay = true;
      //@ts-ignore
      this.player.playVideo();
    },
    playing() {
      console.log(" we are watching!!!");
    }
  }
});
